:root {
    --primary: #D29E84;
    --secondary: #415161;
    --gilroy: Gilroy;
}

.main-services {
    display: flex;
    justify-content: space-between;
    column-gap: 1.4rem;
    row-gap: 2.5rem;
    margin: 6rem 0.1rem;
}

.services {
    width: 45.3rem;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1.2rem;
    border: 1.5px solid var(--primary);
    background: rgba(210, 158, 132, 0.16);
    cursor: pointer;
}

.services:nth-child(2) {
    width: 58.5rem;
}

.services-image {
    width: 16.2rem;
    height: 16.2rem;
    /* background-color: red; */
    margin-bottom: 3.2rem;
    position: relative;
}

.services-gifts-img, .services-ribbon-img {
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    max-width: 12rem;
}

.services-review-img {
    position: absolute;
    top: 2.1rem;
    left: 1.7rem;
    max-width: 14rem;
}

.services-circle {
    fill: #F1F1F1;
    stroke-width: 1px;
    stroke: #D29E84;
    width: 16.3rem;
    height: 16.2rem;
}

.services h1 {
    font-size: 2.6rem;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;
}

.services p {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.03rem;
}

@media only screen and (max-width: 860px) {
    .main-services {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .services {
        width: 100rem;
    }

    .services:nth-child(2) {
        width: 100rem;
    }

    .services-circle {
        width: 13rem;
        height: 12.9rem;
    }

    .services-image {
        width: 11.2rem;
        height: 11.2rem;
    }

    .services-image img {
        width: 9rem;
        height: 9rem;
    }

    .services-gifts-img, .services-ribbon-img {
        top: 2rem;
        left: 2rem;
        
    }
}