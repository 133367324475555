:root {
  --primary: #d29e84;
  --secondary: #415161;
  --gilroy: Gilroy;
}

.giftpack-header {
  text-align: center;
  font-size: 4rem;
  font-weight: 400;
  line-height: 7.2rem;
  text-transform: uppercase;
  /* border-bottom: 2px dashed var(--primary); */
  width: fit-content;
  margin-inline: auto;
  font-family: "Cutive", serif;
  padding-bottom: 1rem;
}

.giftpack-parent {
  margin-top: 7.6rem;
  width: 100%;
  max-width: 111.2rem;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.giftpack-parent:nth-child(2) {
  flex-direction: row-reverse;
}
.giftpack-parent:nth-child(3),
.giftpack-parent:nth-child(4) {
  margin-top: 4.6rem;
}

.giftpack-child {
  /* height: 57.1rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.2rem;
  flex-shrink: 0;
  object-fit: cover;
}

.giftpack-child:first-child {
  width: 38.2rem;
}

.giftpack-child:last-child {
  width: 65.7rem;
  text-align: justify;
}

.giftpack-child-odd:first-child {
  width: 55.7rem;
}

.giftpack-child-odd:last-child {
  width: 38.2rem;
}

.giftpack-image {
  width: 100%;
  height: 100%;
}

.giftpack-child:nth-child(2) {
  justify-content: center;
}

.giftpack-child-odd:nth-child(1) {
  justify-content: center;
}

.giftpack-child h1 {
  font-size: 4.2rem;
  font-weight: 700;
}

.giftpack-child p {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.4rem;
}

.giftpack-child p span {
  font-weight: 500;
}

.button-primary {
  /* min-height: 10px !important; */
  padding: 0 2em !important;
  border-radius: 1rem;
  background: var(--primary);
  color: #fff;
  font-size: 2em !important;
  font-weight: 600;
  /* line-height:; */
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  cursor: pointer;
}

.button-primary:hover {
  color: var(--primary);
  background: #fff;
  border: 1.5px solid var(--primary);
  transition: 0.2s all ease;
}

.button-primary:hover .heart-svg {
  fill: rgb(211, 12, 128) !important;
  transition: 0.2s all ease;
}

@media only screen and (max-width: 1140px) {
  .giftpack-parent {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 5rem;
  }

  .giftpack-child:first-child {
    width: 100%;
    height: 100%;
  }

  .giftpack-image {
    width: 42.4rem;
    margin-inline: auto;
  }

  .giftpack-child:last-child {
    width: 100%;
    height: 100%;
  }

  .giftpack-parent:nth-child(3) {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 480px) {
  .giftpack-child:last-child {
    width: 100%;
  }
  .giftpack-parent:nth-child(2) {
    flex-direction: column-reverse;
  }

  .giftpack-child-odd:first-child {
    width: 100%;
  }

  .giftpack-image {
    width: 32.4rem;
    margin-inline: auto;
  }
}
