.gifts-top-image {
  width: 100%;
  /* background-color: #f3f3f3; */
  font-size: 8rem;
  height: 70rem;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  position: relative;
}

@media only screen and (max-width: 500px) {
  .gifts-top-image {
    object-fit: cover !important;
    height: auto;
  }
}
