.prod-card-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-width: 17rem;
  min-height: 40rem;
  margin: auto;
  cursor: pointer;
}
.prod-card-img-container {
  width: auto;
}
.prod-image {
  width: 200px;
  height: 200px;
  margin: auto;
  border-radius: 5px;
}
.prod-card-title {
  font-size: 1.7rem;
  font-weight: 200;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 200px;
}

@media only screen and (max-width: 650px) {
  .prod-card-container {
    width: 15rem;
    min-height: 35rem;
    justify-content: center;
    align-items: center;
  }
  .prod-card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    font-size: 12px;
    text-align: center;
  }
  .prod-image {
    width: 100px;
    height: 100px;
  }
}
