.addToCart-btn {
  background-color: white;
  color: #d29e84;
  border: 2px solid #d29e84;
  font-size: 1.5rem;
  width: 15rem;
  padding: 0.5rem;
  white-space: nowrap;
}

@media only screen and (max-width: 470px) {
  .addToCart-btn {
    width: 100%;
    font-size: 16px;
    padding: 1rem;
  }
}
