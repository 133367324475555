.order-gift-main-container {
  position: relative;
  height: 500px;
  width: 100%;
}

.carousel-image {
  width: 200px;
  margin: auto;
}

.carousel-info-container {
  background-color: #f2f6f7;
  height: 450px;
  width: 50%;
  margin: auto;
  display: flex;
  align-items: end;
}

.carousel-info-container .info {
  display: block;
  padding: 2rem;
}

.slider-container {
  width: 100% !important;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  width: 100%;
}

.slider-container .slick-slide.slick-center img {
  height: 280px;
  width: 280px;
  transition: height 0.8s ease-in-out, width 0.8s ease-in-out;
}
.slider-container .slick-track {
  display: flex;
  justify-content: space-between;
  transition: flex 0.8s ease-in-out;
  gap: 2rem;
}
.slider-container .slick-slide {
  transition: flex 0.8s ease-in-out;
  min-height: 180px;
  width: 280px;
}
.sliding-box {
  /* width: 100px; */
  /* min-height: 100px; */
  /* object-fit: cover; */
}

/* This css is also applied on the above carousel compoent */
.slick-track {
  display: flex;
  align-items: end;
}
.slick-slide {
  transition: flex 0.8s ease-in-out;
  flex: 1;
}

@media screen and (max-width: 1100px) {
  .carousel-image {
    width: 150px;
    margin: auto;
  }
  .slider-container .slick-slide.slick-center img {
    height: 200px;
    width: 200px;
  }
  .carousel-info-container {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .carousel-image {
    width: 70px;
    margin: auto;
  }
  .slider-container .slick-slide.slick-center img {
    height: 100px;
    width: 100px;
  }
  .carousel-info-container {
    width: 90%;
    height: 250px;
  }
  .carousel-info-container .info {
    font-size: 16px;
  }
  .order-gift-main-container {
    height: 300px;
  }
  .slider-container .slick-slide {
    min-height: 100px;
  }
}
