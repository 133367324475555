.image-in-slider {
  width: 200px !important;
  height: 200px !important;
  object-fit: cover !important;
}

@media only screen and (max-width: 480px) {
  .image-in-slider {
    width: 100px !important;
    height: 100px !important;
  }
}
