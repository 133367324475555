.custom-carousel-container {
  font-family: Arial, sans-serif;
  max-width: 100%;
}

.carousel-multi-item {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.carousel-item {
  transition: transform 0.5s ease;
}

.carousel-item-content {
  flex: 1;
  padding: 4rem 25px 10rem 25px;
  height: 450px;
  width: 100%;
  text-align: left;
  background-color: #a0522d; /* The brown color from your design */
  color: white;
  border-radius: 6.5rem;
  font-size: medium;
}

.carousel-item-content h1 {
  font-weight: bold;
  font-size: xx-large;
}

.carousel-item-content h2 {
  font-size: x-large;
}

.each-slide {
  padding: 3rem;
}
