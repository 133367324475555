.collection-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
  /* padding: 1rem; */
}

.collection-header {
  width: 100%;
  font-size: 4rem;
  text-align: left;
}

.collection-body {
  /* display: flex; */
  width: 100%;
  /* justify-content: center; */
  gap: 5rem;
  /* padding: 2rem; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.collection-footer {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1000px) {
  .collection-body {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 650px) {
  .collection-body {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem !important;
  }
  .collection-header {
    font-size: 3rem;
  }
  .collection-container {
    margin: 2rem 0;
  }
}
