.priceTag-container {
  background-color: #d29e84;
  padding: .5rem 1rem;
  border-radius: 999px;
  color: white;
  font-size: 1.7rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 10rem;
  margin: 0 2rem;
}
@media only screen and (max-width: 460px) {
  .priceTag-container {
    font-size: 12px;
    width: auto;
    max-width: 300px;
    padding: .5rem 2rem;
  }
}
