:root {
  --primary: #d29e84;
  --secondary: #415161;
  --gilroy: Gilroy;
}

.main-product-category {
  margin-top: 7rem;
  display: flex;
  width: 100%;
  min-height: 52rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9.2rem;
  flex-shrink: 0;
  border-radius: 1rem;
  border: 1.5px solid var(--primary);
  background: rgba(210, 158, 132, 0.16);
  padding: 5rem;
  margin-bottom: 10rem;
}

.main-product-category h1 {
  text-align: center;
  font-size: 4rem;
  font-weight: 400;
  line-height: 7.2rem;
  text-transform: uppercase;
  /* border-bottom: 2px dashed var(--primary); */
  font-family: "Cutive", serif;
}

.categories-parent {
  display: flex;
  gap: 7.5rem;
  flex-wrap: wrap;
}

.categories-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 3rem;
}

.categories-child > * {
  flex-basis: 100%;
}

.categories-child p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.categories-child > img {
  width: 6rem;
}

@media only screen and (max-width: 1080px) {
  .main-product-category {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding: 1rem;
  }

  .main-product-category h1 {
    font-size: 3rem;
  }

  .categories-parent {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .categories-child p {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 460px) {
  .categories-child > img {
    width: 6rem;
  }
  .categories-child p {
    font-size: 2rem;
  }
}
