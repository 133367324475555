/* Media queries for responsive sizing */

.navbar-collapse.collapse.show {
  order: 1;
}

.heartsy-logo img {
  width: 20rem;
  margin-left: 8rem;
}
.heartsy-logo-2 {
  display: none;
}
.heartsy-logo-2 img {
  width: 5rem;
  height: 5rem;
  margin-left: 8rem;
}

.activeNavLink {
  border-radius: 5px;
  background-image: url('../assets/images/active-tab-bg.png');
  background-size: 100% 100%;
  color: white;
  width: min-content;
}



@media only screen and (max-width: 800px) {
  /* Heartsybox logo size adjustment for smaller screens */
  .heartsy-logo img {
    width: 20rem;
    margin-left: 8rem;
  }

  /* Hamburger icon size adjustment for smaller screens */
  .custom-toggler {
    transform: scale(1.7);
    order: -1; /* Move the toggler to the beginning */
  }

  /* Cart icon size adjustment for smaller screens */
  .cart-icon img {
    width: 5rem;
  }
  .hamburger-icon {
    size: 1.9rem;
  }
}
@media only screen and (max-width: 990px) {
  .myNav {
    display: none !important;
  }
}

@media only screen and (max-width: 550px) {
  .heartsy-logo {
    display: none;
  }
  .heartsy-logo-2 {
    display: block;
  }
  .heartsy-logo-2 img{
    width: 50px;
    height: auto;
  }
}

@media only screen and (max-width: 350px) {
  /* Heartsybox logo size adjustment for smaller screens */
  .heartsy-logo img {
    width: 15rem;
    margin-left: 12rem;
  }

  /* Hamburger icon size adjustment for smaller screens */
  .custom-toggler {
    transform: scale(1.7);
    order: -1; /* Move the toggler to the beginning */
  }

  /* Cart icon size adjustment for smaller screens */
  .cart-icon img {
    width: 4rem;
  }
}
@media only screen and (max-width: 330px) {
  /* Heartsybox logo size adjustment for smaller screens */
  .heartsy-logo img {
    width: 10rem;
    margin-left: 1rem;
  }

  /* Hamburger icon size adjustment for smaller screens */
  .custom-toggler {
    transform: scale(1.1);
    order: -1; /* Move the toggler to the beginning */
  }

  /* Cart icon size adjustment for smaller screens */
  .cart-icon img {
    width: 2rem;
  }
  .hamburger-icon {
    max-width: 0.8rem;
  }
}
