.readyMade-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.readyMade-top-image {
  width: 100%;
  height: 70rem;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  position: relative;
}

.desktopBanner {
  display: block;
  width: 100%;
}

.phoneBanner {
  display: none;
  width: 100%;
}

@media only screen and (max-width: 1100px) {
  .readyMade-top-image {
    object-fit: cover;
  }
  .desktopBanner {
    width: auto;
  }
}

@media only screen and (max-width: 470px) {
  .readyMade-top-image {
    height: auto;
  }
  .phoneBanner {
    display: block;
  }
  .desktopBanner {
    display: none;
  }
  /* .bannerText {
  } */
}
