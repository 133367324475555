@import url("https://fonts.googleapis.com/css2?family=Cutive&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@font-face {
  font-family: "Tangerin";
  src: local("Tangerin"), url(./fonts/tangerine-webfont.ttf) format("truetype");
}
@font-face {
  font-family: "Euclid Circular A";
  src: local("Euclid Circular A"),
    url(./fonts/Euclid-Circular-A-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"), url("./fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
:root {
  --primary: #d29e84;
  --secondary: #415161;
  --heading-font: "Tangerin";
  --paragraph-font: "Euclid Circular A";
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--paragraph-font);
}

html {
  font-size: 47.5%;
}

body {
  color: var(--secondary);
  background: #f1f1f1;
  font-family: var(--paragraph-font);
}

.header-span {
  color: var(--primary);
  font-family: var(--heading-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font) !important;
}

/* @media only screen and (max-width: 1440px) {
  html {
    font-size: 42.25%;
  }
}


@media only screen and (max-width: 1300px) {
  html {
    font-size: 35.25%;
  }
}

@media only screen and (max-width: 880px) {
  html {
    font-size: 32.25%;
  }
} */
