.giftBox-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: self-end;
  padding: 10rem;
  gap: 5rem;
}

.giftBox-container .img-container {
  flex: 1;
  background-image: url("https://heartsybox.my.canva.site/ready-made/images/002225b61b37977f5629841db7b06f64.jpg");
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 500px;
  overflow: hidden;
  flex-shrink: 0;
}

.info-container {
  flex: 1;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.info p {
  font-size: 2rem;
  font-weight: normal;
  /* font-family: Nunito; */
}

.title-container {
  background-color: #874e31;
  color: white;
  font-size: 4rem;
  padding: 1rem;
  width: 35rem;
  position: absolute;
  top: 20%;
  left: 32%;
  z-index: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: tangerin;
}

@media only screen and (max-width: 1300px) {
  .giftBox-container {
    gap: 2rem;
  }

  .giftBox-container .img-container {
    height: 400px;
    /* width: 300px; */
  }
}

@media only screen and (max-width: 900px) {
  .giftBox-container {
    gap: 5rem;
    width: 100%;
  }

  .giftBox-container .img-container {
    height: 350px;
  }
  .giftBox-container .title-container {
    padding: 0;
    top: 15%;
    /* width: 40rem; */
  }
}

@media only screen and (max-width: 640px) {
  .giftBox-container {
    gap: 3rem;
  }

  .giftBox-container .img-container {
    height: 300px;
  }
  .giftBox-container .title-container {
    padding: 0;
    top: 10%;
    width: 40rem;
  }
}

@media only screen and (max-width: 470px) {
  .giftBox-container {
    flex-direction: column;
    align-items: self-start;
    width: 100%;
    padding: 2rem;
  }

  .giftBox-container .img-container {
    padding: 30rem 10rem;
    order: 1 !important;
  }
  .giftBox-container .info-container {
    order: 2 !important;
  }
  .giftBox-container .info {
    align-items: center !important;
  }
  .giftBox-container .title-container {
    position: relative;
    width: 100%;
    left: 0;
    text-align: center !important;
    padding: 1rem 0;
  }
}
