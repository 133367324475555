.by-colllection-btn {
  background-color: #d29e84;
  color: white;
  border: none;
  padding: 1rem;
  width: 32rem;
  font-size: 3rem;
  white-space: nowrap;
}
@media only screen and (max-width: 470px) {
  .by-colllection-btn {
    width: 85%;
    font-size: 4rem;
    padding: 2rem;
  }
}
