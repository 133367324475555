.app-root-container {
  margin: auto 21.5rem;
  overflow: hidden;
}

@media only screen and (min-width: 2020px) {
  .app-root-container {
    margin: auto 20%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1440px) {
  .app-root-container {
    margin: auto 15rem;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1240px) {
  .app-root-container {
    margin: auto 14rem;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1024px) {
  .app-root-container {
    margin: auto 11rem;
    overflow: hidden;
  }
}

@media only screen and (max-width: 768px) {
  .app-root-container {
    margin: auto 2.8rem;
    overflow: hidden;
  }
}

@media only screen and (max-width: 480px) {
  .app-root-container {
    margin: auto 2.4rem;
    overflow: hidden;
  }
}
