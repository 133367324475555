/* Custom CSS for step form components */

.step-form-container {
  padding-top: 20px;
  max-width: 960px; /* Adjust based on your preference */
}

.step-indicator-row {
  margin-bottom: 30px;
}

.step-indicator-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff2eb;
}

.step-btn {
  border: none;
  background-color: #d29e84;
  color: #fff2eb; /* Your inactive step color */
  font-weight: bold;
  width: 40px; /* Set width and height to make them round */
  height: 40px;
  line-height: 40px; /* Center the text vertically */
  border-radius: 50%; /* Round shape */
  margin: 0 4px; /* Spacing between buttons */
  position: relative;
  font-size: 1.9rem;
}
.step-btn-border {
  border: 1px solid #fff;
}

.step-active {
  background-color: #d29e84; /* Your active step background color */
  color: white;
}

.step-inactive:hover {
  background-color: #f0f0f0; /* Hover background color for inactive steps */
}

.step-text {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 10px;
}
.step-text-inactive {
  color: #d29e84;
}
.step-text-active {
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  .step-text {
    display: none;
  }
}

/* Navigation buttons style */
.step-navigation {
  padding-top: 20px; /* Space from the form to the navigation buttons */
}

.custom-btn-back {
  color: white; /* Text color for the primary button */
  background-color: #ddb892; /* Background color */
  border: 2px solid #ddb892; /* Border color */
  border-radius: 6px; /* Rounded corners */
  padding: 1.3rem 2.4rem; /* Padding inside the button */
  margin: 0; /* Reset margin */
  font-size: 1.5rem; /* Font size for text */
  /* Other styles as needed */
  display: flex;
  align-items: center;
  gap: 3rem;
}

.custom-btn-back:hover {
  background-color: #ddb892; /* Color when hovered */
  color: white; /* Text color when hovered */
}

.custom-btn-back:disabled {
  color: #aaa; /* Color when the button is disabled */
  border-color: #aaa; /* Border color when the button is disabled */
  background-color: #eee; /* Light background when the button is disabled */
}

.custom-btn-next {
  color: white; /* Text color for the primary button */
  background-color: #ddb892; /* Background color */
  border: 2px solid #ddb892; /* Border color */
  border-radius: 6px; /* Rounded corners */
  padding: 1.2rem 2.4rem; /* Padding inside the button */
  margin: 0; /* Reset margin */
  font-size: 1.5rem; /* Font size for text */
  /* Other styles as needed */
  display: flex;
  align-items: center;
  gap: 3rem;
}

.custom-btn-next:hover {
  background-color: #bb9561; /* Darker background color when hovered */
  border-color: #bb9561; /* Darker border color when hovered */
}

.custom-btn-next:disabled {
  background-color: #eee; /* Light background when the button is disabled */
  color: #aaa; /* Color when the button is disabled */
}

.arrow-left,
.arrow-right {
  font-size: 2.5rem; /* Size of the arrow */
  vertical-align: middle;
  line-height: 0; /* Aligns the arrow correctly */
}
